import React from "react"
import empFoundationImg from "../static/images/Datasheets/foundation.png"
import pryzmImg from "../static/images/Datasheets/pryzm-ai.png"

import "../static/style/componentStyle/download-btn.scss"
import lumiqDataSheet from "../static/pdfs/LUMIQ_Data_Sheet.pdf"
import  pryzmBrochure from "../static/pdfs/Pryzm_Brochure.pdf"

import "../static/style/componentStyle/underlineButton.scss"


const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const DatasheetCard = (props) => {
  const truncatedTitle = truncateText(props?.val.title, 75); 
  const imageMapping = {
    "empFoundationImg": empFoundationImg,
    "pryzmImg": pryzmImg,
    // Add more mappings for other images as needed
  };
  const text=props.val.title;
  const pdf=props.val.pdf;
  const selectedImage = imageMapping[props.val.imageUrl];
  return (
    <>
      {/* <Link className="blogs" to={`/resources/detailedBlog/${props?.val?.attributes?.slug}`} state={{ data: props }} target="_blank" name="blog" > */}
      <a className="blogs"
          href={
            pdf == "pryzmBrochure.pdf"
              ? pryzmBrochure
              : lumiqDataSheet
              
          }
          download={
            pdf == "pryzmBrochure.pdf"
              ? "PRYZM_Brochure"
              :"LUMIQ_DataSheet"
          }
        >
      {/* <div className="blogs"> */}
        <div className="blog-header">
          {/* <p>{props?.val?.attributes?.date}</p> */}
          <div className="blog-img">
            <img className="blog-image" src={selectedImage} />
            
          </div>
          <span className="blog-title">{truncatedTitle}</span>
        </div>
        <div className="blog-footer">
          {/* <p className="blog-title">{truncateddescription}</p> */}
          <div className="read-more">
                <p>Download</p>
          </div>
        </div>
      {/* </Link> */}
      {/* </div> */}
      </a>
    </>
  )
}

export default DatasheetCard
